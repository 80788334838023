import React, {useState, useEffect} from 'react';
import './App.css';
import {ApolloClient, ApolloProvider, InMemoryCache, useQuery, useMutation} from "@apollo/client";
import {createServiceMutation, servicesQuery} from "./graphql";

import ServiceForm from './components/ServiceForm.js';
import ServiceList from './components/ServiceList.js';

const client = new ApolloClient({
  uri: 'https://ux-interview-backend.onrender.com/graphql',
  cache: new InMemoryCache()
});

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <AppElements />
      </ApolloProvider>
    </div>
  );
}

function AppElements() {
  const [state, setState] = useState("list");
  const [createdServices, setCreatedServices] = useState([]);
  const [lastCreatedService, setLastCreatedService] = useState();

  const services = useQuery(servicesQuery);
  const [createService, {data}] = useMutation(createServiceMutation);

  const newServiceId = data?.createService;

  // TRICKY: Wait for “newServiceId” to have a value before
  //         adding the new service to the list.
  // TODO: Could this cause an unexpected state, if another
  //       service is created before this gets its “newServiceId”?
  useEffect(() => {
    if (lastCreatedService && newServiceId) {
      setCreatedServices([
        {...lastCreatedService, id: newServiceId},
        ...createdServices
      ]);
      setLastCreatedService(null);
    }
  }, [newServiceId, lastCreatedService, createdServices]);
  
  // KUDOS: https://github.com/davidkpiano/xstate
  const dispatch = (event) => {
    console.log(`dispatch`);
    console.log({state, event});
    const machine = {
      "list": {
        SHOW_FORM: "form",
        RETRY_LAST_CREATE: "list",
      },
      "form": {
        SHOW_LIST: "list",
        CREATE_SERVICE: "list",
      },
    };

    const nextState = machine[state][event.type];

    if (nextState) {
      doAction(event);
      setState(nextState);
    } else {
      console.log(`An unexpected event happened: ${event.type}`);
    }
  };

  const doAction = (event) => {
    if (event.type === "SHOW_FORM") setState("form");
    if (event.type === "SHOW_LIST") setState("list");
    if (event.type === "CREATE_SERVICE") {
      createService({ variables: event.service });
      setLastCreatedService(event.service);
    }
    if (event.type === "RETRY_LAST_CREATE") {
      // 1) Remove the last created service
      const list = [...createdServices];
      list.pop();
      setCreatedServices(list);

      // 2) Retry
      createService({ variables: event.service });
      setLastCreatedService(event.service);
    }
  }

  return (
    <div data-state={ state }>
        <header className="header">
          <a
            href="#create"
            onClick={e => {
              e.preventDefault();
              dispatch({type: "SHOW_LIST"});
            }}>
            My services
          </a>
        </header>
        <main>

          {/* TRICKY: Remove the form from the DOM, so will reset between uses */}
          { state === "form" && (
            <ServiceForm dispatch={dispatch} />
          ) }

          {/* TRICKY: Keep the service list in the DOM, to avoid re-rendering the “createdServices” */}
          <ServiceList
            services={services}
            createdServices={createdServices}
            dispatch={dispatch}
            />
        </main>
    </div>
  );
}

export default App;

import './ServiceStatus.css';
import {useSubscription} from "@apollo/client";
import {serviceUpdatedSubscription} from "../graphql";

function ServiceStatus({service, dispatch}) {

  const {data, loading, error} = useSubscription(
    serviceUpdatedSubscription,
    {
      variables: {id: service.id},
      fetchPolicy: "no-cache"
    }
  );

  if (loading) {
    return (
      <div className="service-status service-status--pending">
        Creating service... <small>This may take up to 60 seconds.</small>
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="service-status service-status--error">
        Oops! Something went wrong.
        <button
          type="button"
          onClick={() => {
            dispatch({type: "RETRY_LAST_CREATE", service});
          }}
        >
          Try again
        </button>
      </div>
    );
  }

  console.log(`Service created: ${data?.serviceUpdated?.id}`);

  return (
    <div className="service-status service-status--success">Running</div>
  );
}

export default ServiceStatus;

export const SERVICE_TYPES = new Map([
  ["web", "Web Service"],
  ["private", "Private Service"],
  ["background", "Background Worker"],
  ["cron", "Cron Job"],
]);

export const SERVICE_ENVIRONMENTS = new Map([
  ["docker", "Docker"],
  ["elixir", "Elixir"],
  ["go", "Go"],
  ["node", "Node"],
  ["python", "Python 3"],
  ["ruby", "Ruby"],
  ["rust", "Rust"],
]);

import { gql } from "@apollo/client";

export const servicesQuery = gql`
  query services {
    services {
      id
      name
      type
      environment
      numInstances
      createdAt
      state
    }
  }
`;

export const serviceQuery = gql`
  query service($id: Int!) {
    service(id: $id) {
      id
      name
      type
      environment
      numInstances
      createdAt
      state
    }
  }
`;

export const createServiceMutation = gql`
  mutation createService($name: String!, $type: String!, $environment: String!, $numInstances: Int!) {
    createService(name: $name, type: $type, environment: $environment, numInstances: $numInstances)
  }
`;

export const deleteServiceMutation = gql`
  mutation deleteService($id: Int!) {
    deleteService(id: $id)
  }
`;

export const serviceUpdatedSubscription = gql`
  subscription serviceUpdated($id: Int!) {
    serviceUpdated(id: $id) {
      id
      name
      type
      environment
      numInstances
      createdAt
      state
    }
  }
`;

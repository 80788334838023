import './ServiceForm.css';
import {useState} from 'react';

import {SERVICE_TYPES, SERVICE_ENVIRONMENTS} from '../service-labels.js';

function CreateForm({dispatch}) {
  const [name, setName] = useState("");
  const [type, setType] = useState("web");
  const [environment, setEnvironment] = useState("docker");
  const [numInstances, setNumInstances] = useState(1);

  return (
    <form
      className="service-form"
      onSubmit={e => {
      e.preventDefault();
      dispatch({type: "CREATE_SERVICE", service: { name, type, environment, numInstances }});
    }}>
      <h1>Create a service</h1>
      <ol className="service-form__list">
        <li className="service-form__list-item">
          <label>
            <span
              id="label-service-name"
              className="field__label">
              Service name
            </span>
            <input
              name="name"
              type="text"
              aria-labelledby="label-service-name"
              className="field__input"
              required
              value={name}
              onChange={e => setName(e.target.value)}
              />
            <span className="mdc-line-ripple"></span>
          </label>
          <small className="note">Example: “My awesome service”</small>
        </li>
        <li className="service-form__list-item">
          <label>
            <span
              id="label-service-type"
              className="field__label">
              Type
            </span>
            <select
              name="type"
              value={type}
              onChange={e => setType(e.target.value)}
              aria-labelledby="label-service-type"
            >
              {[...SERVICE_TYPES.entries()].map(([key, label]) => {
                return (<option key={key} value={key}>{label}</option>);
              })}
            </select>
          </label>
        </li>
        <li className="service-form__list-item">
          <label>
            <span
              id="label-service-environment"
              className="field__label">
              Environment
            </span>
            <select
              name="environment"
              value={environment}
              onChange={e => setEnvironment(e.target.value)}
              aria-labelledby="label-service-environment"
            >
              {[...SERVICE_ENVIRONMENTS.entries()].map(([key, label]) => {
                return (<option key={key} value={key}>{label}</option>);
              })}
            </select>
          </label>
        </li>
        <li className="service-form__list-item">
          <label>
            <span
              id="label-num-instances"
              className="field__label">
              Number of instances
            </span>
            <input
              name="numInstances"
              type="number"
              aria-labelledby="label-num-instances"
              min="1"
              value={numInstances}
              onChange={e => setNumInstances(Number(e.target.value))} />
            <span className="mdc-line-ripple"></span>
          </label>
        </li>
      </ol>
      <p className="service-form__submit">
        <button type="submit">
          Create
        </button>
      </p>
    </form>
  );
}

export default CreateForm;

import './ServiceList.css';
import ServiceStatus from '../components/ServiceStatus.js';
import {SERVICE_TYPES, SERVICE_ENVIRONMENTS} from '../service-labels.js';

function DataTable({services, createdServices, dispatch}) {

  const {loading, error, data} = services;

  if (loading) {
      return <p>Loading services...</p>
  }
  if (error) {
      console.log(error);
      return <p className="error-message">Error loading services</p>
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>
              Service name
            </th>
            <th>
              Type
            </th>
            <th>
              Environment
            </th>
            <th>
              Number of instances
            </th>
            <th>
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {createdServices.map(service => (
            <tr className="service-item--fade-in" key={service.id}>
              <td>
                {service.name}
              </td>
              <td>
                {SERVICE_TYPES.get(service.type)}
              </td>
              <td>
                {SERVICE_ENVIRONMENTS.get(service.environment)}
              </td>
              <td>
                {service.numInstances}
              </td>
              <td className="service-list__status">
                { service.id && (
                  <ServiceStatus service={service} dispatch={dispatch} />
                ) }
                { !service.id && "Status unknown" }
              </td>
            </tr>
          ))}
          {[...data.services].reverse().map(service => (
            <tr key={service.id}>
              <td>
                {service.name}
              </td>
              <td>
                {SERVICE_TYPES.get(service.type)}
              </td>
              <td>
                {SERVICE_ENVIRONMENTS.get(service.environment)}
              </td>
              <td>
                {service.numInstances}
              </td>
              <td className="service-list__status">
                <span className="service-status service-status--success">Running</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function ServiceList({services, createdServices, dispatch}) {
  return (
    <section className="service-list">
      <header className="service-list__header">
        <h1>My services</h1>
        <a
          href="#create"
          onClick={e => {
            e.preventDefault();
            dispatch({type: "SHOW_FORM"});
          }}>
          Create a service
        </a>
      </header>
      <DataTable
        services={services}
        createdServices={createdServices}
        dispatch={dispatch}
        />
    </section>
  );
}

export default ServiceList;
